import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { ShadowedBox } from '../../styles/ui.styles'
import { colors as c } from '../../styles/colors';

const Container = css`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  color: ${c.blackText};
  margin-bottom: 7vw;
  transition: color .1s linear;
  padding: 0 30px;

  @media (min-width: 400px) {
  flex-basis: 50%;
  }

  @media (min-width: 850px) {
    flex-basis: 33.3333%;
    display: flex;
    flex-direction: column;
    margin-bottom: 56px;
  }

  &:hover {
    color: ${c.bluePrimary};
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: auto;

    @media (min-width: 768px) {
    }

    img {
      border: 1px solid ${c.grayBorder};
    }
  }

  h2 {
    font-size: 1.8rem;
    line-height: 1.4;
    padding: 16px 4px 4px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex: 1;

    i {
      align-self: flex-start;
    }
  }
`;

export const InnerContent = styled.div`
  ${ShadowedBox};
  border-radius: 4px;
  padding: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Internal = styled(Link)`
  ${Container}
`;

export const External = styled.a`
  ${Container}
`;

export const Modal = styled.div`
  ${Container}
  cursor: pointer;
`;