import React from 'react'
// Components
import Img from 'gatsby-image';
// Style
import {
  External,
  InnerContent,
  Internal,
  Modal
} from './ShowcaseItem.styles';

const ShowcaseItem = ({
  children,
  image,
  linkType="internal",
  onClick,
  target="_self",
  to
}) => {

  const Inner = () => {
    return(
      <InnerContent>
        <Img
          alt="alt"
          fluid={image} />
        {children}
      </InnerContent>
    )
  }

  return(
    <>
    {linkType === "internal" &&
      <Internal to={to} target={target}>
        <Inner />
      </Internal>
    }
    {linkType === "external" &&
      <External href={to} target={target}>
        <Inner />
      </External>
    }
    {linkType === "modal" &&
      <Modal onClick={onClick}>
        <Inner />
      </Modal>
    }
  </>
  );
}

export default ShowcaseItem;